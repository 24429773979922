import React from 'react';
import { connect } from 'react-redux';
import { PulseLoader } from 'halogenium';
import Calendar from './Calendar';
import TimeTable from './TimeTable';
import {
    selectDay,
    loadNext,
    loadPrev,
    selectTime,
    selectMultipleTime,
    newEvent
} from '../actions';
import qs from "qs";
import {Visible} from "../../common/components/visible";
import {_t} from "../../js/translation";
import moment from "moment";
import {customFormattedDate} from "../../common/utilities/DateTimeUtilities";
import {FormattedTime} from "react-intl";
import {isMilitaryTimeFormat} from "../../common/utils";
import _ from "lodash";
import SelectedTimes from "./SelectedTimes";
import {Heading} from "../../app/common/ui-kit";
import IfScreen from "../../app/common/ui-kit/if.screen";

class Scheduling extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.isDayFromPropsSet = false;
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.availability || nextProps.availability.length === 0) return;

        const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});

        if (!this.props.selectedDayDate) {
            if (!this.isDayFromPropsSet) {
                if (query.multiple_times && query.timezone && window.isMultipleMeeting) {
                    let setSelectTime = false;
                    let now = moment().tz(query.timezone);
                    query.multiple_times.forEach((dateTime) => {
                        let momentDateStart = moment.utc(dateTime).tz(query.timezone);
                        if (momentDateStart > now) {
                            if (!setSelectTime) {
                                this.props.selectTime(momentDateStart.format('YYYY-MM-DD HH:mm:ssZ'), momentDateStart.format('YYYY-MM-DD'));
                                setSelectTime = true;
                            }
                            this.props.selectMultipleTime(momentDateStart.format('YYYY-MM-DD HH:mm:ssZ'), momentDateStart.format('YYYY-MM-DD'));
                        }
                    });

                    this.props.newEvent();
                } else if(query.day && query.time && query.timezone) {
                    let momentDateStart = moment.utc(query.day + " " + query.time).tz(query.timezone);
                    this.props.selectDay(momentDateStart.format('YYYY-MM-DD'));
                    if (momentDateStart > moment().tz(query.timezone)) {
                        if (window.isMultipleMeeting) {
                            this.props.selectMultipleTime(momentDateStart.format('YYYY-MM-DD HH:mm:ssZ'), momentDateStart.format('YYYY-MM-DD'));
                        } else {
                            this.props.selectTime(momentDateStart.format('YYYY-MM-DD HH:mm:ssZ'), momentDateStart.format('YYYY-MM-DD'));
                        }
                    }

                    this.props.newEvent();
                } else if (query.day) {
                    this.props.selectDay(query.day);
                }
            }
            this.isDayFromPropsSet = true;
        }

        if (this.props.selectedDayDate !== nextProps.selectedDayDate) {
            query.day = nextProps.selectedDayDate;
            query.time = null;
            query.timezone = null;
            query.multiple_times = null;
            history.pushState(null, '',
                `${window.location.protocol}//${window.location.host}${window.location.pathname}${qs.stringify(query, {
                    addQueryPrefix: true,
                    skipNulls: true
                })}`);
        }
    }

    continueMultipleMeeting() {
        let firstDay = Object.keys(this.props.selectedTimes)[0];
        let firstTime = this.props.selectedTimes[firstDay][0];

        this.props.onSelectTime(firstTime, firstDay);
    }

    render() {
        return [
            <section key="section2" className="event-block--select-time">

                <Visible visible={this.props.isUserReschedule}>
                    <div className={'warning-message warning-message--reschedule'}>
                        <h4 className={'title'}> {_t('Please note!')} </h4>
                        <p> {_t('The rescheduling allows to select non-working dates/times.')}</p>
                        <p> {_t('The booking may be cancelled if the new date/time is not available.')}</p>
                    </div>
                </Visible>

                <div className={`wrapper wrapper__calendar wrapper__full`}>
                    {window.isMultipleMeeting ?
                        <h4 className={"multiple-meeting--block_top-info multiple-meeting--block_title"}>
                            {_t('You can choose one or more time slots to make several appointments in one time')}
                        </h4>
                    : null}
                    {(this.props.isLoading ?
                            <div style={{alignItems: 'center', textAlign: 'center'}}>
                                <PulseLoader color="#5bb9f4" size="16px" margin="4px"/>
                                <span className={'theme_styles--text'} style={{...{fontSize: '13px'}}}>
                                    {_t('scheduling.loading_text_%userFullName%', {userFullName: this.props.hostName})}
                                </span>
                            </div>
                            :
                            <section className={'event-block--step event-block--step-calendar-and-hours ' + (window.calendarLayout === 'weekly' ? 'calendar-and-hours--weekly' : 'calendar-and-hours--monthly')}>
                                <TimeTable
                                    isAvailablePeriod={this.props.isAvailablePeriod}
                                    item={this.props.selectedDay}
                                    selectedDayDate={this.props.selectedDayDate}
                                    onSelectTime={this.props.onSelectTime}
                                    onSelectMultipleTime={this.props.onSelectMultipleTime}
                                    selectedTimezone={this.props.selectedTimezone}
                                    eventOriginalSchedule={this.props.eventOriginalSchedule}
                                    changeDurationId={this.props.changeDurationId}
                                    fetchItems={this.props.fetchItems}
                                    loadNext={this.props.loadNext}
                                    selectDay={this.props.selectDay}
                                    selectedTimes={this.props.selectedTimes}
                                />
                                <div id='event-block--step-item__calendar-days' className='event-block--step event-block--step-item__calendar-days'>
                                    <Calendar
                                        isAvailablePeriod={this.props.isAvailablePeriod}
                                        availability={this.props.availability}
                                        onClick={this.props.selectDay}
                                        loadNext={this.props.loadNext}
                                        loadPrev={this.props.loadPrev}
                                        fetchItems={this.props.fetchItems}
                                        selectedTimes={this.props.selectedTimes}
                                        selectedDayDate={this.props.selectedDayDate}
                                        eventOriginalSchedule={this.props.eventOriginalSchedule}
                                        calendarLayout={this.props.calendarLayout}
                                    />
                                    <IfScreen desktop tablet>
                                        {!_.isEmpty(this.props.selectedTimes) ?
                                            <section className={'multiple-meeting--wrapper'}>
                                                <div className="multiple-meeting--content">
                                                    <div className={'multiple-meeting--header'}>
                                                        <h4 className={'title title--h4 step-title'}>{_t('Summary')}</h4>
                                                    </div>
                                                    <div className={'multiple-meeting--body'}>
                                                        <SelectedTimes
                                                            selectedTimes={this.props.selectedTimes}
                                                            selectedTimezone={this.props.selectedTimezone}
                                                            onSelectMultipleTime={this.props.onSelectMultipleTime}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'btn-bar multiple-meeting--btn-bar'}>
                                                    <button className="btn btn--primary theme_styles--button" onClick={this.continueMultipleMeeting.bind(this)}>
                                                        {_t('Continue')}
                                                    </button>
                                                </div>
                                            </section>
                                        : null }
                                    </IfScreen>
                                </div>

                                <IfScreen phone>
                                    {!_.isEmpty(this.props.selectedTimes) ?
                                        <section className={'multiple-meeting--wrapper'}>
                                            <div className="multiple-meeting--content">
                                                <div className={'multiple-meeting--header'}>
                                                    <h4 className={'title title--h4 step-title'}>{_t('Summary')}</h4>
                                                </div>
                                                <div className={'multiple-meeting--body'}>
                                                    <SelectedTimes
                                                        selectedTimes={this.props.selectedTimes}
                                                        selectedTimezone={this.props.selectedTimezone}
                                                        onSelectMultipleTime={this.props.onSelectMultipleTime}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'btn-bar multiple-meeting--btn-bar'}>
                                                <button className="btn btn--primary theme_styles--button" onClick={this.continueMultipleMeeting.bind(this)}>
                                                    {_t('Continue')}
                                                </button>
                                            </div>
                                        </section>
                                        : null }
                                </IfScreen>
                            </section>
                    )}
                </div>
            </section>
        ];
    }
}

const mapStateToProps = (state) => ({
    isAvailablePeriod: state.isAvailablePeriod,
    availability: state.availability,
    isUserReschedule: state.isUserReschedule,
    calendarLayout: state.calendarLayout,
    selectedDay: state.selectedDay,
    selectedDayDate: state.selectedDayDate,
    selectedTimes: state.selectedTimes,
    isLoading: state.isLoading,
    next: state.next,
    prev: state.prev
});


const mapDispatchToProps = (dispatch) => ({
    selectDay: (id) => dispatch(selectDay(id)),
    selectTime: (time, day) => dispatch(selectTime(time, day)),
    selectMultipleTime: (time, day) => dispatch(selectMultipleTime(time, day)),
    loadNext: () => dispatch(loadNext()),
    loadPrev: () => dispatch(loadPrev()),
    newEvent: () => dispatch(newEvent()),
    dispatch: dispatch
});


export default connect(mapStateToProps, mapDispatchToProps)(Scheduling);
