import React from 'react';

export class Visible extends React.Component {

    render() {
        if (this.props.visible) {
            return this.props.children;
        }
        return null;
    }

}