import {
    GET_AVAILABILITY,
    SELECT_DAY,
    SELECT_TIME,
    SELECT_MULTIPLE_TIME,
    SELECT_TIMEZONE,
    CLEAR_SELECTED_TIME,
    NEW_EVENT,
    EDIT_EVENT,
    START_LOADING,
    STOP_LOADING,
    LOAD_NEXT,
    LOAD_PREV,
    EVENT_CONFIRMED,
    SET_ERROR,
    EVENT_RESCHEDULED,
    SELECT_INITIAL_DATE,
    SET_LAST_AVAILABLE_OFFSET,
    SET_INLINE_PAYMENT_BODY,
    SET_DURATION_ID,
    CHANGE_DURATION_ID, BACK_TO_CALENDAR_VIEW
} from "./actions";

import * as _ from "lodash";
import { convertDateToUTCDay } from "../common/utilities/DateTimeUtilities";
import moment from "moment";
import qs from "qs";

const initialState = {
    availability: [],
    daysByDate: {},
    isLoading: true,
    timezone: null,
    lastAvailableOffset: 0,
    isAvailablePeriod: false,
    next: false,
    prev: false,
    offset: null,
    isEventConfirmed: false,
    isUserReschedule: false,
    calendarLayout: null,
    durationId: null,
    inlinePaymentBody: null,
    lang: window.userLanguage
};

export default function schedulingReducer(state = initialState, action) {
    switch (action.type) {

    case GET_AVAILABILITY: {
        let selectedDayDate = null;
        let selectedDay = null;
        let isAvailablePeriod = false;
        let next = state.next;
        let prev = state.prev;
        const daysByDate = {};
        const timesByDate = {};

        const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        let setDate = !!query.day;

        const availability = action.data.data.slots.map((item) => {
            daysByDate[item.date] = item;
            timesByDate[item.date] = item.hours;

            if (item.available) {
                isAvailablePeriod = true;
            }
            if (item.available && !setDate && !query.day) {
                setDate = true;
                selectedDayDate = item.date;
                selectedDay = item;
            }

            return item;
        });

        let lastAvailableOffset = state.lastAvailableOffset

        if (!setDate) {
            if (!prev) {
                next = true;
                prev = false;
            }
        } else {
            lastAvailableOffset = action.data.data.offset;
        }

        return {
            ...state,
            availability,
            daysByDate,
            timesByDate,
            next,
            prev,
            lastAvailableOffset,
            isAvailablePeriod,
            isLoading: false,
            selectedDayDate: selectedDayDate,
            selectedDay: selectedDay,
            offset: action.data.data.offset,
            isUserReschedule: action.data.data.is_user_reschedule,
            calendarLayout: action.data.data.calendar_layout,
        };
    }

    case SET_DURATION_ID: {
        return {...state, durationId: parseInt(action.durationId)}
    }

    case CHANGE_DURATION_ID: {
        return {
            ...state,
            durationId: parseInt(action.durationId),
            next: false,
            pref: false,
            selectedTimes: null,
        }
    }

    case SELECT_INITIAL_DATE: {
        let initialDate = new Date(action.initialDate);
        let startDate = new Date(window.startDate);
        if(isNaN(initialDate.getTime())) return state;
        if(isNaN(startDate.getTime())) return state;

        initialDate = convertDateToUTCDay(initialDate);
        startDate = convertDateToUTCDay(startDate);
        if (startDate >= initialDate) return state;

        let offset = 0;
        if (window.calendarLayout === 'weekly') {
            offset = moment(initialDate).diff(moment(startDate).startOf('isoWeek'), 'week');
        } else {
            offset = moment(initialDate).diff(moment(startDate).startOf('month'), 'month');
        }

        if (offset === 0) {
            return state;
        }

        return {...state, next: true, prev: false, offset: offset-1};
    }

    case SELECT_DAY: {
        if (!action.id || !state.daysByDate[action.id] || state.daysByDate[action.id].hours.length === 0) {
            return {
                ...state,
                selectedDayDate: null,
                selectedDay: null,
                isLoading: false,
                errors: null
            };
        }

        return {
            ...state,
            isLoading: false,
            selectedDayDate: action.id,
            selectedDay: state.daysByDate[action.id],
            errors: null,
        };
    }

    case SET_LAST_AVAILABLE_OFFSET: {
        return {
            ...state,
            lastAvailableOffset: action.offset,
        };
    }

    case SELECT_TIME: {
        return {
            ...state,
            selectedDayDate: action.day || null,
            selectedDay: action.day ? state.daysByDate[action.day] : null,
            selectedTime: action.time,
            errors: null,
        };
    }

    case CLEAR_SELECTED_TIME: {
        return {
            ...state,
            selectedDayDate: null,
            selectedDay: null,
            selectedTime: null,
            selectedTimes: null,
            errors: null,
        }
    }

    case SELECT_MULTIPLE_TIME: {
        let selectedTimes = state.selectedTimes ?? {};

        if (_.has(selectedTimes, action.day)) {
            const dayTimes = selectedTimes[action.day];
            if (_.includes(dayTimes, action.time)) {
                _.pull(dayTimes, action.time);
            } else {
                dayTimes.push(action.time);
            }
        } else {
            selectedTimes[action.day] = [action.time];
        }

        if (!selectedTimes[action.day].length) {
            delete selectedTimes[action.day];

            if (_.isEmpty(selectedTimes)) {
                return {
                    ...state,
                    // selectedDayDate: null,
                    // selectedDay: null,
                    selectedTime: null,
                    selectedTimes: null,
                    errors: null,
                }
            }
        }

        if (state.newEvent) {
            state.newEvent.multiple_scheduled_at = _.values(selectedTimes).flat();
        }

        return {
            ...state,
            errors: null,
            // selectedDay: action.day ? state.daysByDate[action.day] : null,
            selectedTimes: {...selectedTimes}
        }
    }

    case BACK_TO_CALENDAR_VIEW: {
        return {
            ...state,
            selectedTime: null,
            newEvent: null,
            errors: null,
        };
    }

    case SELECT_TIMEZONE: {
        return {
            ...state,
            next: false,
            prev: false,
            timezone: action.timezone || null,
            errors: null,
        };
    }

    case START_LOADING: {
        return {...state, isLoading: true};
    }

    case STOP_LOADING: {
        return {...state, isLoading: false};
    }

    case LOAD_NEXT: {
        return {
            ...state,
            next: true,
            prev: false,
            selectedDayDate: null,
            selectedDay: null
        };
    }

    case LOAD_PREV: {
        return {
            ...state,
            prev: true,
            next: false,
            selectedDayDate: null,
            selectedDay: null,
            offset: state.lastAvailableOffset
        };
    }

    case SET_INLINE_PAYMENT_BODY: {
        return {
            ...state,
            inlinePaymentBody: action.inlinePaymentBody
        };
    }

    case EDIT_EVENT: {
        let newEvent = state.newEvent;

        switch (action.property.name) {
            case 'location':
            case 'payment_system':
                newEvent[action.property.name] = action.property.value;
                break;
            case 'location_type':
                if (action.property.value !== 'request') {
                    delete newEvent['location'];
                }
                newEvent[action.property.name] = action.property.value;
                break;
            case 'participants':
                newEvent[action.property.name] = [];
                action.property.value.map((email) => {
                    newEvent[action.property.name].push({email: email});
                })
                break;
            case 'answers':
                if(!action.property.questionId) break;

                const answer = _.find(newEvent.answers, ['question', action.property.questionId]);

                if (null == answer) {
                    newEvent.answers.push({
                        value: action.property.value,
                        question: action.property.questionId
                    });
                } else {
                    answer.value = action.property.value;
                }
                break;
            default:
                newEvent.invitee[action.property.name] = action.property.value;
                break;
        }

        return {...state, newEvent: newEvent, errors: null};
    }

    case EVENT_CONFIRMED: {
        let newEvent = state.newEvent;

        newEvent.multiple_scheduled_at_with_uuid = {};
        newEvent.multiple_scheduled_at_with_uuid[newEvent.scheduled_at] = newEvent.uuid;
        if (!_.isEmpty(newEvent.child_related_events)) {
            _.forEach(newEvent.child_related_events, (event) => {
                let childEventScheduledAt = moment(event.scheduled_at).tz(event.invitee.timezone)
                newEvent.multiple_scheduled_at_with_uuid[childEventScheduledAt.format('YYYY-MM-DD HH:mm:ssZ')] = event.uuid;
            });
        }

        return {...state, newEvent: newEvent, isEventConfirmed: true, errors: null};
    }

    case EVENT_RESCHEDULED: {
        return {...state, newEvent: state.newEvent, isEventConfirmed: true, errors: null};
    }

    case NEW_EVENT: {
        const questions = window.questions;
        let answers = [];

        _.each(questions, function(question) {
            answers.push({
                value: '',
                question: question.uuid
            });
        });

        const newEvent =  {
            duration_id: state.durationId,
            scheduled_at: state.selectedTime ? state.selectedTime : null,
            event_type: null,
            invitee: {
                email: '',
                full_name: '',
                timezone: state.timezone,
                locale: state.lang
            },
            participants: null,
            details: null,
            answers: answers
        };

        if (window.eventTypeShowPhone) {
            newEvent.invitee.phone_number = '';
        }

        if (window.requestLocation) {
            newEvent.location = '';
        }

        if (window.isMultipleMeeting && state.selectedTimes && !_.isEmpty(state.selectedTimes)) {
            newEvent.multiple_scheduled_at = _.values(state.selectedTimes).flat();
        }

        return {...state, newEvent: action.newEvent ? action.newEvent : newEvent, errors: null};
    }

    case SET_ERROR:
        return {...state, errors: action.errors, isLoadingButton: false};

    default:
        return state;
    }
}
