import React from 'react';
import {parseDate, formatDate} from '../../common/utils';
import {FormattedDate} from 'react-intl';
import {_t} from "../../js/translation";
import {customFormattedDate} from "../../common/utilities/DateTimeUtilities";

class CalendarItem extends React.Component {
    constructor(props) {
        super(props);
    }

    onClickItem(date) {
        this.props.onClick(date);

        setTimeout(function () {
            var element = document.getElementById("event-block--step-item__calendar-days");
            //console.log('scroll to block');
            element.scrollIntoView({behavior: "smooth"});
        }, 800);
    }

    render() {
        const isMonthlyCalendarLayout = window.calendarLayout === 'monthly';
        const item = this.props.item;
        const date = item.date;
        const selectedMonth = this.props.selectedMonth;
        const parsedDate = parseDate(date);
        const isToday = parsedDate.isSame(new Date(), 'day');
        let isActiveItem = this.props.selectedDayDate == date;
        let countSelectedTimes = 0;

        if (this.props.selectedTimes && this.props.selectedTimes.hasOwnProperty(date)) {
            countSelectedTimes = this.props.selectedTimes[date].length;
        }

        let isDayEqualToOriginalScheduleDay = false;

        if (this.props.eventOriginalSchedule) {
            const parsedOriginalScheduleDate = parseDate(this.props.eventOriginalSchedule);
            isDayEqualToOriginalScheduleDay = !!this.props.eventOriginalSchedule && parsedDate.isSame(parsedOriginalScheduleDate, "day");
        }

        return (
            <li key={date}
                className={
                    `calendar__item ${!item.available ? ' calendar__item--disabled' : ''} 
                    ${isActiveItem ? ' calendar__item--active' : ''} 
                    ${isDayEqualToOriginalScheduleDay ? " calendar__item--original-schedule" : ""} 
                    ${isToday ? "calendar__item--today" : ""}
                    ${isActiveItem ? "theme_styles--button" : "theme_styles--tile"}
                    ${isMonthlyCalendarLayout && selectedMonth !== parsedDate.format('MMMM') ? 'calendar__item--not-available' : ''}
                    ${!isActiveItem && countSelectedTimes > 0 ? ' calendar__item--multiple-selected ' : ''}`
                }
                onClick={(e) => item.available ? this.onClickItem(date) : e.preventDefault()}
            >
                <span className="calendar__day">{customFormattedDate(parsedDate, 'weekday', 'short')}</span>
                <span className="calendar__date">
                    <FormattedDate
                        value={parsedDate}
                        day='numeric'
                    />
                </span>
                <span className="calendar__month">{customFormattedDate(parsedDate, 'month', 'short')}</span>
                { isToday ?
                    <span className="calendar__info theme_styles--tile">{_t('Today')}</span> :
                    !item.available ?
                        <span className="calendar__info theme_styles--tile">{_t('n/a')}</span> :
                        null
                }
                {countSelectedTimes > 0 ? <span className={'calendar__multiple-select'}>{countSelectedTimes}</span> : null}
            </li>
        );
    }
}

export default CalendarItem;
