import SchedulingService from "../common/services/SchedulingService";
import { setErrorHandler } from "../common/utils";
import * as _ from "lodash";

const schedulingService = new SchedulingService();

export const GET_AVAILABILITY = "GET_AVAILABILITY";

export function getAvailability(data) {
  return {
    type: GET_AVAILABILITY,
    data
  };
}

export const SET_LAST_AVAILABLE_OFFSET = "SET_LAST_AVAILABLE_OFFSET";

export function setLastAvailableOffset(offset) {
  return {
    type: SET_LAST_AVAILABLE_OFFSET,
    offset
  };
}

export const SELECT_DAY = "SELECT_DAY";

export function selectDay(id) {
  return function(dispatch) {
    dispatch(select(id));
  };
}

function select(id) {
  return { type: SELECT_DAY, id };
}

export const SELECT_TIME = "SELECT_TIME";

export function selectTime(time, day) {
  return { type: SELECT_TIME, time, day };
}

export const SELECT_MULTIPLE_TIME = 'SELECT_MULTIPLE_TIME';

export function selectMultipleTime(time, day) {
  return { type: SELECT_MULTIPLE_TIME, time, day };
}

export const CLEAR_SELECTED_TIME = 'CLEAR_SELECTED_TIME';

export function clearSelectedTime() {
  return { type: CLEAR_SELECTED_TIME };
}

export const BACK_TO_CALENDAR_VIEW = "BACK_TO_CALENDAR_VIEW";

export function backToCalendarView() {
  return { type: BACK_TO_CALENDAR_VIEW };
}

export const SELECT_TIMEZONE = "SELECT_TIMEZONE";

export function selectTimezone(timezone) {
  return { type: SELECT_TIMEZONE, timezone };
}

export const SELECT_INITIAL_DATE = "SELECT_INITIAL_DATE";

export function setInitialDate(initialDate) {
  return { type: SELECT_INITIAL_DATE, initialDate };
}

export const SET_DURATION_ID = "SET_DURATION_ID";

export function setDurationId(durationId) {
  return { type: SET_DURATION_ID, durationId };
}

export const CHANGE_DURATION_ID = "CHANGE_DURATION_ID";

export function changeDurationId(durationId) {
  return { type: CHANGE_DURATION_ID, durationId };
}

export const LOAD_NEXT = "LOAD_NEXT";

export function loadNext() {
  return { type: LOAD_NEXT };
}

export const LOAD_PREV = "LOAD_PREV";

export function loadPrev() {
  return { type: LOAD_PREV };
}

export const NEW_EVENT = "NEW_EVENT";

export function newEvent(data) {
  return { type: NEW_EVENT, data };
}

export const SET_INLINE_PAYMENT_BODY = "SET_INLINE_PAYMENT_BODY";

export function setInlinePaymentBody(inlinePaymentBody) {
  return { type: SET_INLINE_PAYMENT_BODY, inlinePaymentBody: inlinePaymentBody };
}

export const EVENT_CONFIRMED = "EVENT_CONFIRMED";

export function eventConfirmed(event) {
  return { type: EVENT_CONFIRMED, event };
}

export const EVENT_RESCHEDULED = "EVENT_RESCHEDULED";

export function eventRescheduled(event) {
  return { type: EVENT_RESCHEDULED, event };
}

export const EDIT_EVENT = "EDIT_EVENT";

export function editEvent(property) {
  return { type: EDIT_EVENT, property };
}

export const SET_ERROR = "SET_ERROR";

export function setError(errors) {
  return { type: SET_ERROR, errors };
}

export const START_LOADING = "START_LOADING";

export function startLoading() {
  return { type: START_LOADING };
}

export const STOP_LOADING = "STOP_LOADING";

export function stopLoading() {
  return { type: STOP_LOADING };
}

/**
 * Fetches events
 */
export function fetchAvailability(eventTypeId, tryToFetch = 0) {
  const isReschedule = window.isReschedule || false;

  return function(dispatch, getState) {
    let incrementNumber = getState().next ? 1 : -1;

    dispatch(startLoading());
    return schedulingService
      .get(
        eventTypeId,
        getState().timezone,
        getState().next,
        getState().prev,
        getState().offset,
        isReschedule,
        window.eventUuid,
        getState().durationId
      )
      .then(data => {
        dispatch(getAvailability(data));
        if (tryToFetch <= 10 && !window.eventTypeDurationsValue) {
          if (!_.find(data.data.slots, {'available': true})) {
            dispatch(fetchAvailability(eventTypeId, tryToFetch+1))
            if (tryToFetch === 1) {
              dispatch(setLastAvailableOffset(getState().lastAvailableOffset+incrementNumber));
            }
          }
        }
      })
      .catch(error => setErrorHandler(error, dispatch, setError));
  };
}

export function createEvent(eventTypeId) {
  return function(dispatch, getState) {
    let event = getState().newEvent;
    event.event_type = parseInt(eventTypeId);
    let params = {};

    if (window.singleUseLinkCode !== "") {
      params.single_use_link_code = window.singleUseLinkCode;
    }

    dispatch(startLoading());
    return schedulingService
      .post(event, params)
      .then(response => {
        if (response.data.hasOwnProperty("redirect_url")) {
          if (response.data.hasOwnProperty("mode") && response.data.mode === 'inline' && response.data.hasOwnProperty("body")) {
              dispatch(stopLoading());
              dispatch(setInlinePaymentBody(response.data.body));
          } else {
            window.top.location.href = response.data.redirect_url;
          }
        } else {
          //TODO 14184 set location here and add to model group
          dispatch(stopLoading());
          event.uuid = response.data.uuid;
          event.child_related_events = response.data.child_related_events;
          dispatch(eventConfirmed(event));
        }
      })
      .catch(error => {
        dispatch(stopLoading());
        setErrorHandler(error, dispatch, setError);
      });
  };
}

export function rescheduleEvent(uuid, reason) {
  return function(dispatch, getState) {
    let event = getState().newEvent;
    dispatch(startLoading());
    return schedulingService
      .reschedule(uuid, reason, event.scheduled_at)
      .then(response => {
        if (response.data.hasOwnProperty("redirect_url")) {
          window.location.replace(response.data.redirect_url);
        } else {
          event.uuid = uuid;

          dispatch(stopLoading());
          dispatch(eventRescheduled(event));
        }
      })
      .catch(error => {
        dispatch(stopLoading());
        setErrorHandler(error, dispatch, setError);
      });
  };
}