import React from 'react';
import CalendarItem from './CalendarItem';
import {_t} from "../../js/translation";
import moment from "moment";
import * as _ from "lodash";
import {customFormattedDate} from "../../common/utilities/DateTimeUtilities";
import {getDayOfWeekList} from "../scheduling-utils";

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.handleLoadNext = this.handleLoadNext.bind(this);
        this.handleLoadPrev = this.handleLoadPrev.bind(this);
    }
    
    handleLoadNext() {
        this.props.loadNext();
        this.props.fetchItems();
    }

    handleLoadPrev() {
        this.props.loadPrev();
        this.props.fetchItems();
    }

    componentDidMount() {
        let calendarTodayElement = $('.calendar__item--today');
        if (calendarTodayElement.length) {
            $('.calendar__monthly').scrollLeft(calendarTodayElement.position().left);
        }
    }

    render () {
        let months = {};
        let monthList = [];
        let monthValues = [];

        this.props.availability.map((item, index) => {
            let month = moment(item.date).format('YYYY-MM-01');
            months[month] = months[month] ? months[month] + 1 : 1;
        });

        _.map(months, (days, month) => {
            monthList.push(month);
            monthValues.push({month: month, days: days});
        })

        let maxMonth = moment(_.maxBy(monthValues, 'days').month);

        const items = this.props.availability.map((item, index) => {
            return <CalendarItem
                selectedMonth={maxMonth.format('MMMM')}
                key={index}
                item={item}
                onClick={this.props.onClick}
                selectedDayDate={this.props.selectedDayDate}
                selectedTimes={this.props.selectedTimes}
                eventOriginalSchedule={this.props.eventOriginalSchedule}
            />;
            }
        );

        return (
            <React.Fragment>
                <h4 className={"title title--h4 step-title title--select-time theme_styles--text"}>
                    {window.isHideUnavailableDates ? _t('Select available date') : _t('scheduling.select_date')}
                </h4>
                <div className={'calendar-wrapper ' +  ('calendar-wrapper__' + this.props.calendarLayout) + ' ' + this.props.calendarLayout + (window.isHideUnavailableDates ? ' wrapper-calendar__hide-unavailable-dates' : '')}>
                    <div className={'calendar__month-title calendar__month-title-for--' + (this.props.calendarLayout)}>
                        {
                            this.props.calendarLayout === 'monthly'
                                ? customFormattedDate(maxMonth, 'month', 'long')
                                : monthList.map((month) => (
                                    <React.Fragment key={'month_in_week_type_' + month}>
                                        <div className={'calendar__month-title_item'}>
                                            {customFormattedDate(moment(month), 'month', 'long')}
                                        </div>
                                        <span className="divider" key={'divider_' + month}>-</span>
                                    </React.Fragment>
                                ))
                        }
                    </div>
                    <ul className={'calendar__days-title calendar__days-title-for--' + (this.props.calendarLayout)}>
                        {
                            getDayOfWeekList().map((dayOfWeek) => {
                                return <li key={'day_of_week_' + dayOfWeek}><span>{_t(dayOfWeek)}</span></li>;
                            })
                        }
                    </ul>
                    <div className="calendar__monthly--nav">
                        <div onClick={() => this.handleLoadPrev()} className="calendar__nav-btn">
                            <i className="material-icons fal fa-chevron-left"></i>
                        </div>
                        <div onClick={() => this.handleLoadNext()} className="calendar__nav-btn">
                            <i className="material-icons  fal fa-chevron-right"></i>
                        </div>
                    </div>
                    <ul className={"calendar calendar__" + this.props.calendarLayout + (window.isHideUnavailableDates ? ' calendar__hide-unavailable-dates' : '')}>
                        <li className="calendar__nav calendar__nav--left" onClick={() => this.handleLoadPrev()}>
                            <div className="calendar__nav-btn">
                                <i className="material-icons fal fa-chevron-left"></i>
                            </div>
                        </li>

                        {items}

                        <li className="calendar__nav calendar__nav--right" onClick={() => this.handleLoadNext() }>
                            <div className="calendar__nav-btn">
                                <i className="material-icons fal fa-chevron-right"></i>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="calendar__nav-bottom">
                    <div className="calendar__nav-bottom__item left" onClick={() => this.handleLoadPrev() }>
                        <div className="calendar__nav-btn">
                            <i className="material-icons fal fa-chevron-left"></i>
                        </div>
                    </div>
                    <div className="calendar__nav-bottom__item right" onClick={() => this.handleLoadNext() }>
                        <div className="calendar__nav-btn">
                            <i className="material-icons fal fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Calendar;
