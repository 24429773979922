import React, {Component} from "react";
import {connect} from "react-redux";
import {IntlProvider} from "react-intl";
import Scheduling from "./Scheduling";
import NewEvent from "./NewEvent";
import TimezoneSelectField from "./TimezoneSelectField";
import moment from "moment";
import RichTextView from "../../common/components/RichTextView";
import * as _ from "lodash";
import {
    fetchAvailability,
    selectTime,
    selectMultipleTime,
    clearSelectedTime,
    selectTimezone,
    createEvent,
    editEvent,
    newEvent,
    setError,
    rescheduleEvent,
    setInitialDate,
    setDurationId,
    changeDurationId,
    backToCalendarView
} from "../actions";

import qs from "qs";
import {_t} from "../../js/translation";
import LockedTimezoneText from "./LockedTimezoneText";
import getDefaultTimezone from "../../app/common/tools/getDefaultTimezone";

class SchedulingApp extends Component {
    componentWillMount() {
        const isLocalized = this.props.isLocalized || window.isLocalized;
        const eventTypeId = this.props.eventTypeId || window.eventTypeId;
        const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});

        if (isLocalized) {
            this.props.selectTimezone(getDefaultTimezone());
        } else {
            this.props.selectTimezone(this.props.eventTypeTimezone || window.eventTypeTimezone);
        }

        if (query.day && query.timezone && query.time) {
            let momentDateStart = moment.utc(query.day + " " + query.time).tz(query.timezone);
            this.props.setInitialDate(momentDateStart.format('YYYY-MM-DD'));
        } else if(query.day){
            this.props.setInitialDate(query.day);
        }

        if(query.duration_id){
            this.props.setDurationId(query.duration_id);
        }

        this.props.fetchAvailability(eventTypeId);
    }

    handleGoBackToCalendarView(link) {
        if (this.props.isEventConfirmed) {
            window.location.href = link;
        } else {
            this.props.goBackToCalendarView();
        }
    }


    render() {
        const eventTypeName = this.props.eventTypeName || window.eventTypeName;
        const eventTypeColor = this.props.eventTypeColor || window.eventTypeColor;
        const backToLink = this.props.backToLink || window.backToLink;
        const meetingTypeLink = this.props.meetingTypeLink || window.meetingTypeLink;
        const isLocalized = this.props.isLocalized || window.isLocalized;
        const eventTypeDescription = this.props.eventTypeDescription || window.eventTypeDescription;
        const isLoggedIn = this.props.isLoggedIn || window.isLoggedIn;
        const eventOriginalSchedule = this.props.eventOriginalSchedule || window.eventOriginalSchedule;
        const isSecret = this.props.isSecret || window.isSecret;
        const eventTypeTimezone = this.props.eventTypeTimezone || window.eventTypeTimezone;
        const eventTypeId = this.props.eventTypeId || window.eventTypeId;
        const useExternalLocation = this.props.useExternalLocation || window.useExternalLocation;
        const hostName = this.props.hostName|| window.userFullName;
        const eventTypeShowPhone = this.props.eventTypeShowPhone || window.eventTypeShowPhone;
        const questions = this.props.questions || window.questions;
        const cancellationPolicy = this.props.cancellationPolicy || window.cancellationPolicy;
        const simplypayPaymentSystems = this.props.simplypayPaymentSystems || window.simplypayPaymentSystems;
        const eventTypeRequestLocationTypes = this.props.eventTypeRequestLocationTypes || window.eventTypeRequestLocationTypes;
        const requestLocation = this.props.requestLocation || window.requestLocation;
        const eventTypeLocationQuestion = this.props.eventTypeLocationQuestion || window.eventTypeLocationQuestion;

        const {lang} = this.props;

        return (
            <IntlProvider locale={lang} key={lang} messages={LANG}>
                <div className="booking-page__main-wrapper">
                    {this.props.errors && _.has(this.props.errors, "message") && this.props.errors.code != 409 && !_.has(this.props.errors, "errors") ?
                        <section className={'scheduling-page--unavailable'}>
                            <article className={'no-data no-data--scheduling-page--unavailable'}>
                                <div className={'no-data--image'}></div>
                                <h3 className={'title title--h3 no-data--title'}>
                                    {
                                        this.props.errors.code == 403
                                            ? _t('scheduling.errors.cancellation_policy')
                                            : _t('scheduling.errors.calendar_unavailable')
                                    }
                                </h3>
                                {isLoggedIn ?
                                    <React.Fragment>
                                        <div className={'no-data--content'}>
                                            {this.props.errors && _.has(this.props.errors, "code") && this.props.errors.code == 501 ?
                                                <React.Fragment>
                                                    <p className={'p p--subtitle'}>There is an issue with your calendar. Your calendar does not support Free/Busy feature.</p>
                                                    <p className={'p p--hint'}>This message is only visible to you.</p>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <p className={'p p--subtitle'}>There is an issue with your calendar. Please, visit the calendar integrations page to resolve.</p>
                                                    <a className={'btn btn--info'} href='/calendars'>calendar integration page</a>
                                                    <p className={'p p--hint'}>This message is only visible to you.</p>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </React.Fragment>
                                    : null}
                            </article>
                        </section> :
                        <div className="booking-page__main">

                            {window.singleUseLinkCode === '' && this.props.selectedTime ?
                                <React.Fragment>
                                    <div className={'wrapper back-button__wrapper ' + (this.props.selectedTime ? 'has-border' : 'no-border ') + (this.props.selectedTime || this.props.selectedDayDate ? window.singleUseLinkCode !== '' && this.props.isEventConfirmed ? null : ' no-border' : null) }>
                                        <div className="back-button">
                                            <i className="material-icons fal fa-chevron-left"></i>
                                            <a href="#" onClick={() => this.handleGoBackToCalendarView(meetingTypeLink)} className="small theme_styles--link">
                                                {_t('scheduling.back_to_calendar')}
                                            </a>
                                        </div>
                                    </div>
                                </React.Fragment>
                            : !isSecret && window.singleUseLinkCode === '' && !window.specificMeetingType && !window.isBookingFromAdmin ?
                                    <React.Fragment>
                                        <div className={'wrapper back-button__wrapper ' + (this.props.selectedTime ? 'has-border' : 'no-border ') + (this.props.selectedTime || this.props.selectedDayDate ? window.singleUseLinkCode !== '' && this.props.isEventConfirmed ? null : ' no-border' : null) }>
                                            <div className="back-button">
                                                <i className="material-icons fal fa-chevron-left"></i>
                                                <a href="#" onClick={() => window.location.href = backToLink} className="small theme_styles--link">
                                                    {_t('scheduling.back_to_meeting_types')}
                                                </a>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            : null}

                            {this.props.selectedTime ?
                                <NewEvent
                                    eventTypeName={eventTypeName}
                                    eventTypeId={eventTypeId}
                                    eventTypeColor={eventTypeColor}
                                    selectedTimes={this.props.selectedTimes}
                                    selectedTime={this.props.selectedTime}
                                    selectedDayDate={this.props.selectedDayDate}
                                    selectedTimezone={this.props.timezone}
                                    inlinePaymentBody={this.props.inlinePaymentBody}
                                    onSubmit={this.props.createEvent}
                                    onChange={this.props.editEvent}
                                    newEvent={this.props.newEvent}
                                    isEventConfirmed={this.props.isEventConfirmed}
                                    setError={this.props.setError}
                                    errors={this.props.errors}
                                    isLoading={this.props.isLoading}
                                    eventOriginalSchedule={eventOriginalSchedule}
                                    onRescheduleSubmit={this.props.rescheduleEvent}
                                    useExternalLocation={useExternalLocation}
                                    eventTypeShowPhone={eventTypeShowPhone}
                                    questions={questions}
                                    cancellationPolicy={cancellationPolicy}
                                    simplypayPaymentSystems={simplypayPaymentSystems}
                                    eventTypeRequestLocationTypes={eventTypeRequestLocationTypes}
                                    requestLocation={requestLocation}
                                    eventTypeLocationQuestion={eventTypeLocationQuestion}
                                    hostName={hostName}
                                    onSelectMultipleTime={this.props.selectMultipleTime}
                                /> :
                                <section key="Scheduling-header" className="event-block event-block--title-timezone">
                                    <div className="wrapper">
                                        <div className="event-header">
                                            <div className="event-heading theme_styles--header_text">
                                                <div className="event-heading--header">
                                                    <i className="material-icons fal fa-clock primary"></i>
                                                    <h3 className={"title title--h3"}>
                                                        {eventTypeName}
                                                    </h3>
                                                </div>
                                            </div>
                                            {!isLocalized
                                                ?
                                                <LockedTimezoneText
                                                    eventTypeTimezone={eventTypeTimezone}
                                                    lang={this.props.lang}
                                                />
                                                :
                                                <TimezoneSelectField
                                                    selectTimezone={this.props.selectTimezone}
                                                    clearSelectedTime={this.props.clearSelectedTime}
                                                    fetchItems={() => this.props.fetchAvailability(eventTypeId)}
                                                />
                                            }
                                        </div>
                                        {this.props.selectedTime || eventTypeDescription == '' ? null
                                            :
                                            <div className="event-description--wrapper">
                                                <div className="event-description hidden theme_styles--text">
                                                    <RichTextView html={eventTypeDescription}/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </section>
                            }
                            {this.props.selectedTime || this.props.isEventConfirmed ?
                                null :
                                <Scheduling
                                    key="Scheduling"
                                    onSelectTime={this.props.selectTime}
                                    onSelectMultipleTime={this.props.selectMultipleTime}
                                    changeDurationId={this.props.changeDurationId}
                                    selectedTimezone={this.props.timezone}
                                    fetchItems={() => this.props.fetchAvailability(eventTypeId)}
                                    eventOriginalSchedule={eventOriginalSchedule}
                                    hostName={hostName}
                                />
                            }

                        </div>
                    }
                </div>
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedTime: state.selectedTime,
    selectedTimes: state.selectedTimes,
    timezone: state.timezone,
    newEvent: state.newEvent,
    isEventConfirmed: state.isEventConfirmed,
    inlinePaymentBody: state.inlinePaymentBody,
    isLoading: state.isLoading,
    selectedDayDate: state.selectedDayDate,
    errors: state.errors,
    lang: state.lang
});

const mapDispatchToProps = (dispatch) => ({
    fetchAvailability: (eventTypeId) => dispatch(fetchAvailability(eventTypeId)),
    setInitialDate: (initialDate) => dispatch(setInitialDate(initialDate)),
    setDurationId: (durationId) => dispatch(setDurationId(durationId)),
    changeDurationId: (durationId) => dispatch(changeDurationId(durationId)),
    selectTime: (time, day) => {
        dispatch(selectTime(time, day));
        dispatch(newEvent());
    },
    selectMultipleTime: (time, day) => {
        dispatch(selectMultipleTime(time, day));
    },
    selectTimezone: (timezone) => dispatch(selectTimezone(timezone)),
    clearSelectedTime: () => dispatch(clearSelectedTime()),
    createEvent: (eventTypeId) => dispatch(createEvent(eventTypeId)),
    rescheduleEvent: (uuid, reason) => dispatch(rescheduleEvent(uuid, reason)),
    editEvent: (event) => dispatch(editEvent(event)),
    setError: (errors) => dispatch(setError(errors)),
    goBackToCalendarView: () => {
        dispatch(backToCalendarView());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingApp);
