import React from "react";
import {_t} from "../../js/translation";
import {Visible} from "../../common/components/visible";
import Select from "react-select";
import _ from "lodash";
import qs from "qs";

class EventTypeDurations extends React.Component {
    constructor(props) {
        super(props);
    }

    onChangeDuration(durationId) {
        let query = qs.parse(window.location.search, {ignoreQueryPrefix: true});

        query.duration_id = durationId;
        this.setHistoryState(query);

        this.props.changeDurationId(durationId);
        this.props.fetchItems().then(() => {
            this.props.selectDay(query.day);
        });
    }

    setHistoryState(query) {
        history.pushState(null, '',
            `${window.location.protocol}//${window.location.host}${window.location.pathname}${qs.stringify(query, {
                addQueryPrefix: true,
                skipNulls: true
            })}`);
    }

    render() {
        const eventTypeDurationsValue = window.eventTypeDurationsValue;
        let query = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        let queryDurationId = query.duration_id;

        let options = [];
        let defaultValue = null;
        if (eventTypeDurationsValue) {
            _.sortBy(eventTypeDurationsValue, ['label']).map((eventTypeDurationValue) => {
                let option = {
                    value: eventTypeDurationValue.value,
                    label: _t(eventTypeDurationValue.label + ' ' + 'min')
                };
                if (+queryDurationId === +eventTypeDurationValue.value) {
                    defaultValue = option;
                }
                options.push(option);
            });
        }

        if (!defaultValue) {
            defaultValue = _.head(options);
        }

        return (
            <React.Fragment>
                {eventTypeDurationsValue ?
                    <div className='field-container align--center direction--row grid-x-gap-20 grid-y-gap-8'>
                        <h4 className={'title title--h4 step-title'}>{_t('Select meeting duration')}</h4>

                        <Select
                            isSearchable={false}
                            defaultValue={defaultValue}
                            options={options}
                            placeholder={_t('Select duration')}
                            classNamePrefix={'react-select'}
                            className={'form-group'}
                            onChange={(value) => this.onChangeDuration(value.value)}
                        />
                    </div>
                    :
                    <div>
                        <div className='field-container align--center direction--row grid-x-gap-20 grid-y-gap-8'>
                            <h4 className={'title title--h4 step-title'}>
                                {_t('Meeting duration')}: <span className={'meeting-duration'}>{window.eventTypeDuration} {_t('min')}</span>
                            </h4>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default EventTypeDurations;
