import React from "react";
import TimeTableItem from "./TimeTableItem";
import SelectedDaySidebar from "./SelectedDaySidebar";
import {_t} from "../../js/translation";
import moment from "moment";
import {isMilitaryTimeFormat, parseDate} from "../../common/utils";
import {FormattedTime} from "react-intl";
import EventTypeDurations from "./EventTypeDurations";
import {Visible} from "../../common/components/visible";

class TimeTable extends React.Component {
    constructor(props) {
        super(props);

        this.handleLoadNext = this.handleLoadNext.bind(this);
    }

    getItems(hours, item) {
        const times = [];
        let hourKey;
        let newHourKey;
        let formattedTimeProps = {
            timeZone: this.props.selectedTimezone
        };
        if (window.userTimeFormat !== 'auto') {
            // formattedTimeProps.hour12 = !isMilitaryTimeFormat(window.userTimeFormat);
            formattedTimeProps.hourCycle = isMilitaryTimeFormat(window.userTimeFormat) ? 'h23' : 'h12';
        }

        for (var i = 0; i < hours.length; i++) {
            if (hours[i].available) {
                let hoursStart = moment(hours[i].start).tz(this.props.selectedTimezone);
                newHourKey = hoursStart.format('YYYY-MM-DD HH');
                if (hourKey !== newHourKey) {
                    hourKey = newHourKey;
                    times.push(
                        <div key={'divider-key__' + i} className={'time-table__time-divider'}>
                            <FormattedTime value={hoursStart} {...formattedTimeProps}/>
                            <hr/>
                        </div>
                    );
                }
                times.push(
                    <TimeTableItem
                        key={i}
                        id={i}
                        day={item.date}
                        startItem={hours[i]}
                        onClick={window.isMultipleMeeting ? this.props.onSelectMultipleTime : this.props.onSelectTime}
                        selectedTimezone={this.props.selectedTimezone}
                        selectedTimes={this.props.selectedTimes}
                        eventOriginalSchedule={this.props.eventOriginalSchedule}
                    />
                );
            }
        }

        return times;
    }

    handleLoadNext() {
        this.props.loadNext();
        this.props.fetchItems();
    }

    render() {
        const item = this.props.item;
        const hours = item ? item.hours : [];
        const interval = window.eventTypeDuration;
        const timeTableItems = item ? this.getItems(hours, item) : [];

        return(
            <React.Fragment>
                <div className="event-block--step event-block--step-hours">
                    <div key="selected-day-sidebar" className="event-block--step-item event-block--step-item__sidebar sidebar sidebar--selected-data theme_styles--text theme_styles--line">
                        <Visible visible={this.props.selectedDayDate}>
                            <SelectedDaySidebar item={this.props.selectedDayDate}/>
                        </Visible>

                        <div className="scheduled">
                            <ul className="details-list v3">
                                <li className={"details-list__item details-list__item--name"}>
                                    <span className="details-list__item-col details-list__item-col--title">{_t('Meeting name')}</span>
                                    <span className="details-list__item-col details-list__item-col--value">
                                      <i title="Name" className="service-color" style={{ background: eventTypeColor }}></i>
                                        {eventTypeName}
                                    </span>
                                </li>

                                <li className={"details-list__item details-list__item--name"}>
                                    <span className="details-list__item-col details-list__item-col--title">{_t('Duration')}</span>
                                    <span className="details-list__item-col details-list__item-col--value">
                                        {interval} {_t('min')}.
                                    </span>
                                </li>

                                {useExternalLocation ? (
                                    <li className={"details-list__item details-list__item--location"}>
                                        <span className="details-list__item-col details-list__item-col--title">{_t('Location')}</span>
                                        <span className="details-list__item-col details-list__item-col--value">{_t('scheduling.conferencing_details')}</span>
                                    </li>
                                ) : !requestLocation &&
                                ((eventTypeLocation != "" &&
                                    (this.props.isEventConfirmed && eventTypeLocationHidden)) ||
                                    (eventTypeLocation != "" && !eventTypeLocationHidden)) ? (
                                    <li className={"details-list__item details-list__item--location"}>
                                        <span className="details-list__item-col details-list__item-col--title">{_t('Location')}</span>
                                        <span className="details-list__item-col details-list__item-col--value">
                                          {eventTypeLocation}
                                        </span>
                                    </li>
                                ) : null}
                            </ul>
                        </div>
                    </div>

                    <div className="select-time--wrapper">
                        <EventTypeDurations
                            changeDurationId={this.props.changeDurationId}
                            fetchItems={this.props.fetchItems}
                            selectDay={this.props.selectDay}
                        />
                        <br/>

                        <div key="select-time" id='wrapper__calendar-hours' className="event-block--step-item event-block--step-item__calendar wrapper__calendar-hours theme_styles--text">
                            {timeTableItems.length ?
                                <div>
                                    <h4 className={"title title--h4 step-title"}>{_t('scheduling.select_time')}</h4>

                                    <div className="time-table">
                                        {timeTableItems}
                                    </div>
                                </div>
                            :
                                <div className={"calendar__no-slots"}>
                                    {window.eventTypeDurationsValue || this.props.isAvailablePeriod ?
                                        <div className={'calendar__no-slots-content'}>
                                            <div className="theme_styles--header_text">{_t('scheduling.no_slots_change_day_or_period_title')}</div>
                                            <div className={'calendar__no-slots--image'}></div>
                                            <div className="theme_styles--text">{_t('scheduling.no_slots_change_day_or_period_text')}</div>
                                        </div>
                                    :
                                        <div>
                                            <div className={'calendar__no-slots-content'}>
                                                <div className="theme_styles--header_text">{_t('scheduling.no_slots_title')}</div>
                                                <div className={'calendar__no-slots--image'}></div>
                                                <div className="theme_styles--text">{_t('scheduling.no_slots_text')}</div>
                                            </div>
                                            <div className="btn-bar client-form__btn-bar">
                                                <div className="btn-bar--row">
                                                    <input type="button" className="btn btn--primary theme_styles--button" onClick={() => this.handleLoadNext()} value={_t('scheduling.next_period')}/>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TimeTable;
