import React from 'react';
import {_t} from "../../js/translation";
import {Text} from "../../app/common/ui-kit";
import {PAYMENT_SYSTEMS_PAYMENT_METHODS_MAP} from "../../app/common/constant/payment";

class SelectPaymentSystem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const paymentSystems = this.props.allowedPaymentSystems || [];

        return (
            <div className="custom-switch payment-systems">
                <label className={'label custom-switch__title'}>{_t('Select payment processor')}</label>
                <div className={'custom-switch__list v2'}>
                {paymentSystems.map((item, index) => (
                    <label htmlFor={'ps_' + item.paymentSystem} className={'custom-switch__item ps_' + (item.paymentSystem)} key={'ps_' + index}>
                        <input type="radio" name="payment_system" className={'custom-switch__toggler'} value={item.paymentSystem}
                               id={'ps_' + item.paymentSystem} onChange={this.props.onChange}/>
                        <div className="custom-switch__item-content ">
                            <picture className='custom-switch__icon'>
                                <img src={'../../../img/payment-processors--png/' + (item.paymentSystem) + '.png'} alt={item.paymentSystem}/>
                            </picture>

                            {PAYMENT_SYSTEMS_PAYMENT_METHODS_MAP.hasOwnProperty(item.paymentSystem) ?
                                <div className="sub-systems sbpay-sub-systems">
                                    <div className="sbpay-sub-systems--informer">
                                        <div className="sbpay-sub-systems--list">
                                            <Text size={Text.SIZE.SMALL} weight={Text.WEIGHT.NORMAL} className={'sbpay-p'} caption={'You can pay by following subprocessors'}/>
                                            {PAYMENT_SYSTEMS_PAYMENT_METHODS_MAP[item.paymentSystem].map((item, index) => (
                                                <img className="sbpay-sub-systems--list-item" src={'../../../img/payment-processors--png/svg/' + (item) + '.svg'} alt={item} key={'ps_' + index}/>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            : null}

                            <span className={'custom-switch__caption'}>{item.name}</span>
                        </div>
                    </label>
                ))}

                    {this.props.errors && this.props.errors.hasOwnProperty('errors') && this.props.errors.errors.hasOwnProperty('children') && this.props.errors.errors.children.hasOwnProperty('payment_system') ?
                        <small className="form-error">{this.props.errors.errors.children.payment_system.errors}</small> : null}

                </div>
            </div>
        );
    }
}

export default SelectPaymentSystem;
