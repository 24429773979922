import Axios from "axios";
import {SCHEDULING_RESCHEDULE_EVENT_URL} from "../constants/ServiceConstants";
import {
    SCHEDULING_URL,
    SCHEDULING_CREATE_EVENT_URL,
    SCHEDULING_CANCELLATION_EVENT_URL
} from "../constants/ServiceConstants";

export default class SchedulingService {
    constructor() {
        this.servicePath = '/api';
        this.axiosInstance = Axios.create({
            baseURL: this.servicePath,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });
    }

    get(id, timezone, next, prev, offset, isReschedule = false, eventUuid = null, durationId = null) {
        const params = {};
        if (timezone && typeof (timezone) !== "undefined") {
            params.timezone = timezone;
        }

        params.next = next;
        params.prev = prev;
        params.offset = offset;
        params.event_type_id = id;
        params.event_uuid = eventUuid;
        if (durationId) {
            params.duration_id = durationId;
        }
        if (isReschedule) {
            params.reschedule = isReschedule;
        }

        return this.axiosInstance.get(`${SCHEDULING_URL}`, {params: params});
    }

    post(event, params = {}) {
        return this.axiosInstance.post(`${SCHEDULING_CREATE_EVENT_URL}`, event, {params: params});
    }

    reschedule(uuid, reason, scheduledAt) {
        return this.axiosInstance.patch(`${SCHEDULING_RESCHEDULE_EVENT_URL}/${uuid}`, {
            reason,
            scheduled_at: scheduledAt
        });
    }

    cancel(uuid, reason) {
        return this.axiosInstance.patch(`${SCHEDULING_CANCELLATION_EVENT_URL}/${uuid}`, {cancellation: reason});
    }

}
