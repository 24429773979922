import { createStore, render } from '../common/utils';
import SchedulingApp from './components/SchedulingApp';
import schedulingReducer from './reducers';

import './rtl-styles.css';

const store = createStore(schedulingReducer);

window.isRtl = false;

render(
    store,
    SchedulingApp,
    document.getElementById('scheduling')
);
