import React from 'react';
import {parseDate, isMilitaryTimeFormat} from '../../common/utils';
import {FormattedTime} from 'react-intl';
import classNames from "classnames";

class TimeTableItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const startItem = this.props.startItem;

        let formattedTimeProps = {
            timeZone: this.props.selectedTimezone
        };
        if (window.userTimeFormat !== 'auto') {
            formattedTimeProps.hourCycle = isMilitaryTimeFormat(window.userTimeFormat) ? 'h23' : 'h12';
        }

        const startDate = parseDate(startItem.start);
        let isTimeEqualToOriginalScheduleTime = false;

        if (this.props.eventOriginalSchedule) {
            const parsedOriginalScheduleDate = parseDate(this.props.eventOriginalSchedule);

            isTimeEqualToOriginalScheduleTime = !!this.props.eventOriginalSchedule && startDate.isSame(parsedOriginalScheduleDate, "day") &&
                startDate.format("HH:mm") === parsedOriginalScheduleDate.format("HH:mm");
        }

        let isActive = false;
        if (this.props.selectedTimes && this.props.selectedTimes.hasOwnProperty(this.props.day)) {
            isActive = this.props.selectedTimes[this.props.day].includes(startDate.tz(this.props.selectedTimezone).format('YYYY-MM-DD HH:mm:ssZ'));
        }

        return [<div key={`${this.props.id}-range`}
                    onClick={() => this.props.onClick(startItem.start, this.props.day) }
                    className={classNames({
                        'time-table__range': true,
                        'calendar__range--original-schedule': isTimeEqualToOriginalScheduleTime,
                        'theme_styles--button': isActive,
                        'theme_styles--tile': !isActive,
                    })}
        >
            <span className={'start-time'}><FormattedTime value={startDate} {...formattedTimeProps}/></span>
            {startItem.rest && <div className="rest-slots"><span className='rest-slots__row'>{startItem.rest} / {window.eventTypeGroupMeetingLimit}</span></div>}
        </div>];
    }
}

export default TimeTableItem;
