export const PAYMENT_SYSTEMS_PAYMENT_METHODS_MAP = {
    adyen: ['amex', 'discover', 'jcb', 'mastercard', 'unionpay', 'visa'],
    alipay: ['amex', 'boleto', 'jcb', 'mastercard', 'unionpay', 'visa'],
    asiapay: ['amex', 'discover', 'jcb', 'mastercard', 'rupay', 'unionpay', 'visa'],
    authorize: ['amex', 'discover', 'jcb', 'mastercard', 'visa'],
    borgun: ['amex', 'discover', 'jcb', 'mastercard', 'unionpay', 'visa'],
    ccavenue: ['amex', 'discover', 'jcb', 'mastercard', 'rupay', 'visa'],
    dwolla: ['mastercard', 'visa'],
    ecpay: ['jcb', 'mastercard', 'visa'],
    kortais: ['mastercard', 'visa'],
    liqpay: ['mastercard', 'unionpay', 'visa'],
    mollie: ['amex', 'mastercard', 'visa'],
    newebpay: ['jcb', 'mastercard', 'unionpay', 'visa'],
    pagar: ['amex', 'boleto', 'discover', 'jcb', 'mastercard', 'visa'],
    payfast: ['mastercard', 'visa'],
    payfort: ['amex', 'mastercard', 'visa', 'mada'],
    payulat: ['amex', 'boleto', 'mastercard', 'visa'],
    pinpayments: ['amex', 'jcb', 'mastercard', 'unionpay', 'visa'],
    robokassa: ['mastercard', 'mir', 'visa'],
    skrill: ['amex', 'jcb', 'mastercard', 'unionpay', 'visa'],
    square: ['squareup', 'discover', 'jcb', 'mastercard', 'visa'],
    stripe: ['amex', 'discover', 'jcb', 'mastercard', 'unionpay', 'visa'],
    stripe_new: ['amex', 'discover', 'jcb', 'mastercard', 'unionpay', 'visa'],
    swish: ['mastercard', 'visa'],
    tappay: ['amex', 'jcb', 'mastercard', 'unionpay', 'visa'],
    twocheckout: ['amex', 'discover', 'jcb', 'mastercard', 'visa'],
    vcspay: ['amex', 'mastercard', 'visa'],
    worldpay: ['amex', 'discover', 'jcb', 'mastercard', 'visa'],
    zooz: ['amex', 'discover', 'jcb', 'mastercard', 'unionpay', 'visa'],
    braintree: ['visa', 'mastercard', 'amex', 'jcb', 'discover', 'unionpay'],
    paypal_plus: ['visa', 'mastercard', 'amex', 'jcb', 'discover', 'unionpay'],
    linepay: ['visa', 'mastercard', 'unionpay'],
    paypal_express_checkout: ['visa', 'mastercard', 'amex', 'jcb', 'discover', 'unionpay'],
    paypal: ['visa', 'mastercard', 'amex', 'jcb', 'discover', 'unionpay'],
    kaspi: ['visa', 'mastercard', 'unionpay'],
    revolut: ['visa', 'mastercard'],
    paytabs: ['visa', 'mastercard', 'amex'],
    safecharge: ['visa', 'mastercard', 'discover', 'amex'],
};