import React from 'react';
import {_t} from "../../js/translation";
import {refreshTooltips} from "../../common/utilities/FormUtilities";
import {Radio} from "antd";

class SelectRequestLocationType extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const locationTypes = this.props.eventTypeRequestLocationTypes || [];

        return (
            <React.Fragment>
            <div className="custom-switch locations">
                <div className={'custom-switch__list v1'}>
                    {locationTypes.map((item, index) => (
                        <label htmlFor={'ps_' + item} className={'custom-switch__item ps_' + (item)} key={'ps_' + index}>
                            <input type="radio" name="location_type" className={'custom-switch__toggler'} value={item}
                                   id={'ps_' + item} onChange={this.props.onChange}/>
                            <div className="custom-switch__item-content ">
                                <picture className='custom-switch__icon'>
                                    <img src={'../../../img/location-types--png/' + (item) + '.png'} alt={item}/>
                                </picture>
                                <span className={'custom-switch__caption'}>{item === 'custom' ? this.props.eventTypeLocation : _t(item)}</span>
                            </div>
                        </label>
                    ))}

                    {this.props.errors && this.props.errors.hasOwnProperty('errors') && this.props.errors.errors.hasOwnProperty('children') && this.props.errors.errors.children.hasOwnProperty('location_type') ?
                        <small className="form-error">{this.props.errors.errors.children.location_type.errors}</small> : null}

                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default SelectRequestLocationType;
