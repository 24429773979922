import * as _ from "lodash";

function refreshTooltipsFunction(){
    let tooltips = $("[data-toggle='tooltip']");
    if (tooltips.length) {
        tooltips.tooltip();
    }
}

const debouncedRefreshTooltipsFunction = _.debounce(refreshTooltipsFunction, 100);

export function refreshTooltips(){
    debouncedRefreshTooltipsFunction();
}