import React, {Component} from 'react';

class RichTextView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="rich-text"
                dangerouslySetInnerHTML={{
                    __html: this.props.html
                }}>
            </div>
        );
    }
}

export default RichTextView;
