import React from 'react';
import { parseDate } from '../../common/utils';
import {_t} from "../../js/translation";

class LockedTimezoneText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date()
        };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }
    
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    render() {
        return (
            <div className="locked-timezone theme_styles--text">
                {_t('scheduling.times_in_%timezone%_%time%', {
                    timezone: this.props.eventTypeTimezone,
                    time: this.props.lang !== 'en' ?
                        parseDate(this.state.date).tz(this.props.eventTypeTimezone).format('H:mm') :
                        parseDate(this.state.date).tz(this.props.eventTypeTimezone).format('hh:mm a')

                })}
            </div>
        );
    }
}

export default LockedTimezoneText;
